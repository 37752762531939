<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-19 11:18:26
 * @LastEditTime: 2023-09-22 16:54:24
 * @FilePath: \awx-ui\src\pages\taskManage\components\configParams.vue
-->
<template>
  <div class="param-setting">
    <a-form-model ref="tableformRef" :model="form">
      <a-table class="form-table" :columns="column" :data-source="form.list" :customRow="handleClickRow" :scroll="{y: form.list.length > 10 ? 496 : ''}" :rowKey="(record, index)=>index" :pagination="false" :locale="locale">
        <template v-for="item in column" :slot="item.name" slot-scope="text, record,index">
          <div :key="item.name">
            <a-form-model-item :prop="'list.'+index+'.'+ `${item.name}`" :rules="{required: item.name !== 'description' && record.required, message: item.title + '不能为空'}">
              <!-- 名称 -->
              <template v-if="['name'].includes(item.name)">
                <div class="ell show-text" :title="record[`${item.name}`] ? record[`${item.name}`] : '--'">{{ record[`${item.name}`] }}</div>
              </template>
              <!-- switch -->
              <template v-if="['private', 'runtime', 'required'].includes(item.name)">
                <a-switch v-model="record[`${item.name}`]" size="small" :disabled="true"></a-switch>
              </template>
              <!-- 类型 -->
              <template v-if="item.name === 'type'">
                <div class="ell show-text">{{ record[`${item.name}`] ? record[`${item.name}`] === 'text' ? '字符串' : '枚举' : '--' }}</div>
              </template>
              <!-- 选完主机组的默认值 -->
              <a-select v-if="record.type === 'host_group' && item.name === 'value'" v-model="record[`${item.name}`]" :placeholder="'请选择'+ item.title">
                <a-select-option v-for="(child, childIndex) in hostGroupList" :key="childIndex" :value="child.id+''">{{child.name}}</a-select-option>
              </a-select>
              <!-- 选完枚举的默认值 -->
              <template v-if="record.type === 'enum' && item.name === 'value'">
                <a-select v-model="record[`${item.name}`]" :placeholder="'请选择'" @dropdownVisibleChange="(val) => dropdownVisibleChange(val, record, 'value')">
                  <a-select-option v-for="(child, childIndex) in (form.list[index].display || [])" :key="childIndex" :value="child.value">{{child.name}}</a-select-option>
                </a-select>
              </template>
              <!-- 选完字符串的默认值 -->
              <template v-if="record.type === 'text' && item.name === 'value'">
                <a-input v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(record, 'value')" @blur.stop="handleBlur(record, 'value')"></a-input>
              </template>
              <template v-if="item.name === 'description'">
                <div class="flex-container">
                  <a-input v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(record, 'description')" @blur.stop="handleBlur(record, 'description')"></a-input>
                </div>
              </template>
            </a-form-model-item>
          </div>
        </template>
      </a-table>
    </a-form-model>
    <div class="footer-param">
      <a-button class="mgr10" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="confirmLoading" @click="handleSubmit">{{ '确定' }}</a-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

function generateRandomId() {
  const timestamp = Date.now().toString(36); // 使用时间戳生成字符串
  const randomNum = Math.random().toString(36).substr(2, 5); // 使用随机数生成字符串
  return timestamp + randomNum;
}
export default {
  components: {
  },
  data() {
    return {
      keyWord: '',
      paramFrom: {},
      visible: false,
      confirmLoading: false,
      form: {
        list: [],
      },
      syncTableList: [],
      statusList: [],
      rules: {
        enumStr: [
          {
            required: false,
            message: "枚举参数可选值不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      locale: {
        emptyText: <div class="empty-container">暂无自定义参数 </div>,
      },
      typeArr: [
        {
          label: "字符串",
          value: "text",
        },
        // {
        //   label: "主机组",
        //   value: "host_group",
        // },
        {
          label: "枚举",
          value: "enum",
        },
      ],
      column: [
        {
          title: "名称",
          key: "name",
          name: "name",
          width: 134,
          ellipsis: true,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "类型",
          key: "type",
          width: 130,
          ellipsis: true,
          name: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "默认值",
          key: "value",
          width: 125,
          ellipsis: true,
          name: "value",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "是否必填",
          key: "required",
          name: "required",
          width: 90,
          ellipsis: true,
          scopedSlots: { customRender: "required" },
        },
        {
          title: "运行时设置",
          key: "runtime",
          width: 108,
          ellipsis: true,
          name: "runtime",
          scopedSlots: { customRender: "runtime" },
        },
        {
          title: "描述",
          key: "description",
          ellipsis: true,
          name: "description",
          scopedSlots: { customRender: "description" },
        },
      ],
    };
  },
  props: {
    currentTaskConfigs: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentTask: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  watch: {
    tableData: {
      handler(val) {
        // this.initTable(val);
      },
      immediate: true,
    },
    statusList: {
      handler(val) {
        // console.log(val, "状态列表");
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    tableData() {
      const tableData = this.currentTaskConfigs 
      return tableData;
    },
  },
  mounted() {
    this.initTable();
  },
  methods: {
    handleFocus(item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = true;
    },
    handleBlur(item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = false;
    },
    dropdownVisibleChange (val, item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = val;
    },
    handleClickRow(record, index) {
      return {
        style: {},
        on: {
          mouseenter: () => {
            const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
            this.statusList[currentIndex].edit = true;
          },
          mouseleave: () => {
            const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
            if (!this.statusList[currentIndex]['typeFocus'] && !this.statusList[currentIndex]['valueFocus']) this.statusList[currentIndex].edit = false;
          },
        },
      };
    },
    getCurentIndex (record) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
      return currentIndex
    },
    getVal(filed, val, index, item) {
      this.form.list[index].value = val === "text" ? "" : undefined;
    },
    addItem(filed, index, item) {
      this.paramFrom.name = this.form.list[index].name;
      let strArr = [];
      if (!this.form.list[index].display) {
        this.paramFrom.enumStr = "";
      } else {
        strArr = this.form.list[index].display.map((item) => item.name);
        this.paramFrom.enumStr = strArr.join(",");
      }
      this.visible = true;
      this.addItemObj = {
        filed,
        index,
        item,
      };
    },
    handleCancel(e) {
      this.$emit('cancel')
    },
    initTable() {
      let tableData = [];
      if (
        this.currentTaskConfigs && this.currentTaskConfigs.length
      ) {
        this.statusList = []
        this.form.list = []
        this.syncTableList = []
        this.currentTaskConfigs.map((item) => {
          const uuid = generateRandomId()
          if (!item.system) {
            tableData.push({
              ...item,
              uuid
            });
            this.statusList.push({
              edit: false,
              nameFocus: false,
              typeFocus: false,
              descriptionFocus: false,
              valueFocus: false,
              show: true,
              uuid
            });
          }
        });
      }
      this.form.list = _.cloneDeep(tableData);
      this.syncTableList = _.cloneDeep(tableData)
    },
    handleSubmit() {
      this.$refs.tableformRef.validate(async (valid) => {
        if (valid) {
          this.$emit('runTask', this.currentTask, this.form.list, 'configs')
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.param-setting {
  padding: 16px 20px;
  /deep/ .ant-form-item-with-help,
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
  /deep/ .ant-input,
  /deep/ .ant-select-selection {
    height: 28px;
  }
  /deep/ .ant-select-selection {
    .ant-select-selection__rendered {
      line-height: 26px;
    }
  }
  /deep/ .ant-table-tbody > tr > td {
    padding: 4px 8px;
  }
  /deep/ .ant-table-thead > tr > th {
    font-weight: 550;
  }
  .add-param {
    position: absolute;
    top: 8px;
    right: 0px;
    .btn-before-icon {
      position: relative;
      top: -1px;
    }
  }
  .empty-container {
    padding: 10px;
    color: #666;
    .empty-add {
      color: @primary-color;
      cursor: pointer;
    }
  }
  .action-icon {
    cursor: pointer;
  }
}
/deep/ .ant-modal-body {
  padding: 4px 24px 24px !important;
}
.footer-param {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.show-text {
  height: 28px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
}
</style>
